import axios from "axios";

export default {
    methods: {
        async getCompanySettings() {
            var self = this;
            let formData = new FormData();
            formData.append('company', this.selectedCompany)
            await axios.post('/api/get_company_settings.php', formData).then(function (response) {
                if (response.data.success) {
                    self.resetDetails();
                    let settings = JSON.parse(response.data.settings);

                    for (var key in settings) {
                        if (Object.prototype.hasOwnProperty.call(settings, key)) {
                            self.details[key] = settings[key];
                            //console.log(key + " -> " + settings[key]);
                        }
                    }
                    self.setDefaults();
                    self.details.name = '';
                    self.details.jobTitle = '';
                    self.details.email = {label: '', address: ''};
                    self.details.phone1 = {label: '', number: ''};
                    self.details.phone2 = {label: '', number: ''};
                }
            })
                .catch(function (e) {
                    console.log('getCompanySettings failure ' + e);
                });
        },
        setDefaults() {
            if (this.details.legalDisclaimerFontSize === '') {
                this.details.legalDisclaimerFontSize = '11';
            }
            if (this.details.brandColourLineWidth === '') {
                this.details.brandColourLineWidth = '3';
            }
            if (this.details.bannerMaxWidth === '') {
                this.details.bannerMaxWidth = '600';
            }
        },
        async saveCompany() {
            let formData = new FormData();
            let data = JSON.stringify(this.details);
            const self = this;
            formData.append('data', data)
            await axios.post('/api/save_company.php', formData)
                .then(function (response) {
                    if (response.data.success) {
                        console.log('success');
                        self.$parent.toasts.push({
                            title: self.details.companyName + ' saved',
                            body: "All good, company saved fine",
                            type: 'success',
                            show: true
                        });
                    }
                })
                .catch(function (e) {
                    console.log('Failed: ' + e);
                    self.$parent.toasts.push({
                        title: self.details.companyName + ' error',
                        body: "There was an issue saving this company",
                        type: "error",
                        show: true
                    });
                });
            // Add a toast to the $parent.toasts array

        },
        async getCompanies() {
            var self = this;
            await axios.get('/api/get_companies.php',
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                }).then(function (response) {
                if (response.data.success) {
                    self.companies = JSON.parse(response.data.companies);
                }
            })
                .catch(function (e) {
                    console.log('getCompanies Failure ' + e);
                });
        },

    }
}
