import axios from "axios";

export default {
    methods: {
        getClientSettings() {
            var self = this;
            let formData = new FormData();
            formData.append('client', this.selectedClient)
            axios.post('/api/get_client_settings.php', formData).then(function (response) {
                if (response.data.success) {
                    self.resetDetails();
                    let settings = JSON.parse(response.data.settings);

                    for (var key in settings) {
                        if (Object.prototype.hasOwnProperty.call(settings, key)) {
                            self.details[key] = settings[key];
                            //console.log(key + " -> " + settings[key]);
                        }
                    }
                    self.setDefaults();
                }
            })
                .catch(function (e) {
                    console.log('getClientSettings failure ' + e);
                });
        },
        saveClient() {
            let formData = new FormData();
            let data = JSON.stringify(this.details);
            const self = this;
            formData.append('data', data)
            axios.post('/api/save_client.php', formData)
                .then(function (response) {
                    if (response.data.success) {
                        console.log('success');
                        self.$parent.toasts.push({
                            title: self.details.name + ' saved',
                            body: "All good, person saved fine",
                            type: 'success',
                            show: true
                        });
                    }
                })
                .catch(function (e) {
                    console.log('Failed: ' + e);
                    self.$parent.toasts.push({
                        title: self.details.name + ' error',
                        body: "There was an issue saving this person",
                        type: "error",
                        show: true
                    });
                });
        },
        getClients() {
            var self = this;
            let company = this.selectedCompany;
            axios.get(`/api/get_clients.php?company=${company}`,
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                }).then(function (response) {
                if (response.data.success) {
                    self.clients = JSON.parse(response.data.clients);
                }
            })
                .catch(function (e) {
                    console.log('getClients Failure ' + e);
                });
        },
    }
}
