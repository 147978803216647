<template>
  <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
    <li v-for="(template, index) in templates"
        :key="template.styleRef"
        class="nav-item" role="presentation">
      <button class="nav-link" :class="{ 'active': index === 0 }" id="pills-home-tab" data-bs-toggle="pill" :data-bs-target="'#'+ template.styleRef" type="button" role="tab" aria-selected="true">{{ template.name }}</button>
    </li>
  </ul>
  <div class="tab-content" id="pills-tabContent">
    <div v-for="(template, index) in templates"
         :key="template.styleRef"
         :class="{ 'active': index === 0 }"
         class="tab-pane fade show border bg-white" :id="template.styleRef" role="tabpanel">
      <div class="row">
        <div class="col-sm-12">
          <div class="signature-container">
            <component :is="'sig-' + template.styleRef"
                       v-on:signature-html="updateSignatureHtml"
                       :details="details" :base-url="baseUrl"
            ></component>
          </div>
        </div>
        <div class="col-sm-12 mt-3 me-3 mb-3 ms-3">

                        <textarea class="signature-html"
                                  :value="getTemplateHtml(template.styleRef)"
                                  onclick="this.focus(); this.select()">
                        </textarea>
          <button @click="copyHtmlToClipboard(template.styleRef)" class="btn btn-sm btn-secondary">Copy to Clipboard</button>
          {{ copiedToClipboardMessage }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import copyToClipboard from "copy-to-clipboard";

export default {
  name: "SignaturePreview",
  props: ['details', 'baseUrl'],
  data() {
    return {
      copiedToClipboardMessage: '',
      templates: [
        {
          'styleRef': 'style1',
          'name': 'Style 1',
          'html': '',
        },
        {
          'styleRef': 'style2',
          'name': 'Style 2',
          'html': '',
        },
        {
          'styleRef': 'style3',
          'name': 'Style 3',
          'html': '',
        },
        {
          'styleRef': 'style4',
          'name': 'Style 4',
          'html': '',
        }
      ]
    }
  },
  methods: {
    getTemplateHtml(styleRef) {
      const theTemplate = this.templates.find(template => template.styleRef === styleRef);
      //console.log(theTemplate.styleRef + ': ' + theTemplate.html);
      return theTemplate.html;
    },
    copyHtmlToClipboard(styleRef) {
      let text = this.getTemplateHtml(styleRef);
      copyToClipboard(text);
      this.copiedToClipboardMessage = 'Copied!';
    },
    updateSignatureHtml(args) {
      const theTemplate = this.templates.find(template => template.styleRef === args.styleRef);
      theTemplate.html = args.html;
    },
  }
}
</script>

<style scoped>

</style>
