<template>
  <div :ref="this.styleRef">
    <table style="border: none; padding:0; margin:15px 0 0 0; font-size:14px; font-family: sans-serif; max-width:600px" cellpadding="0" cellspacing="0">
      <tr>
        <td style="font-family:sans-serif;">
          <span v-if="details.name" style="font-size:16px; font-weight:bold;">{{ details.name }}</span><br/>
          <template v-if="details.jobTitle">
            {{ details.jobTitle }}<br/>
          </template>
        </td>
      </tr>
      <tr>
        <td style="border-bottom:3px solid #666; padding-bottom:20px; padding-top:10px; text-align:left"
            :style="{borderColor:details.brandColour, borderBottomWidth: details.brandColourLineWidth + 'px'}">
          <template v-if="details.website.linkLogo && details.website.url && details.logoUri">
            <a :href="getLink(details.website.url)">
              <img v-if="details.logoUri" :src="getLogoUri" :alt="details.companyName" style="max-width:200px;">
            </a>
          </template>
          <template v-else>
            <img v-if="details.logoUri" :src="getLogoUri" :alt="details.companyName" style="max-width:200px;">
          </template>
        </td>
      </tr>
      <tr>
        <td style="padding-top:10px">
          <table style="margin:10px 0 10px 0; padding:0; font-size:12px;" cellspacing="0" cellpadding="0">
            <template v-if="details.email.address">
              <tr>
                <td v-if="details.email.label" :style="{color: textColour}" style="font-weight:bold; font-family:sans-serif;">{{
                    details.email.label
                  }}:&nbsp;
                </td>
                <td style="font-family:sans-serif;"><a :href="'mailto:' + details.email.address" :style="{color: textColour}"
                                                       style="text-decoration: none;">{{ details.email.address }}</a></td>
              </tr>
            </template>
            <template v-if="details.phone1.number">
              <tr>
                <td v-if="details.phone1.label" :style="{color: textColour}" style="font-weight:bold; font-family:sans-serif;">{{
                    details.phone1.label
                  }}:&nbsp;
                </td>
                <td :style="{color: textColour}" style="font-family:sans-serif;">{{ details.phone1.number }}</td>
              </tr>
            </template>
            <template v-if="details.phone2.number">
              <tr>
                <td v-if="details.phone2.label" :style="{color: textColour}" style="font-weight:bold; font-family:sans-serif;">{{
                    details.phone2.label
                  }}:&nbsp;
                </td>
                <td :style="{color: textColour}" style="font-family:sans-serif;">{{ details.phone2.number }}</td>
              </tr>
            </template>
            <template v-if="details.website.url">
              <tr>
                <td v-if="details.website.label" :style="{color: textColour}" style="font-weight:bold; font-family:sans-serif;">{{ details.website.label }}:&nbsp;</td>
                <td style="font-family:sans-serif;"><a :href="getLink(details.website.url)" :style="{color: textColour}" style="text-decoration: none;">{{
                    details.website.url
                  }}</a></td>
              </tr>
            </template>
          </table>
          <div v-if="details.extraTextPosition === 'above' && details.extraText" style="margin-bottom:10px; font-size:12px;">
            <span v-html="nl2br(details.extraText)"></span>
          </div>
          <template v-if="details.facebook">
            <a :href="details.facebook"><img :src="getIconUri('facebook')" alt="Facebook" style="width:20px; margin-bottom:4px;"></a>&nbsp;
          </template>
          <template v-if="details.linkedin">
            <a :href="details.linkedin"><img :src="getIconUri('linkedin')" alt="LinkedIn" style="width:20px; margin-bottom:4px;"></a>&nbsp;
          </template>
          <template v-if="details.instagram">
            <a :href="details.instagram"><img :src="getIconUri('instagram')" alt="Instagram" style="width:20px; margin-bottom:4px;"></a>&nbsp;
          </template>
          <template v-if="details.twitter">
            <a :href="details.twitter"><img :src="getIconUri('twitter')" alt="Twitter" style="width:20px; margin-bottom:4px;"></a>&nbsp;
          </template>
          <template v-if="details.youtube">
            <a :href="details.youtube"><img :src="getIconUri('youtube')" alt="YouTube" style="width:20px; margin-bottom:4px;"></a>
          </template>
          <template v-if="details.extraLink.link || details.extraLink.label">
            <div style="margin-top:10px; font-size:12px;">
              <component
                  :is="details.extraLink.link?'a':'span'"
                  :href="getLink(details.extraLink.link) || ''"
                  style="color:#222; text-decoration: none; font-weight:bold;"
                  target="_blank">{{ details.extraLink.label }}
              </component>
            </div>
          </template>
          <div v-if="details.extraTextPosition === 'below' && details.extraText" style="margin-top:10px; font-size:12px;">
            <span v-html="nl2br(details.extraText)"></span>
          </div>
        </td>
      </tr>
    </table>
    <table style="border: none; padding:0; margin:10px 0 30px 0; font-family: sans-serif" cellpadding="0" cellspacing="0">
      <tr v-if="details.showBanner && details.bannerUri">
        <td style="font-family:sans-serif;">
          <component
              :is="details.bannerLink?'a':'span'"
              :href="getLink(details.bannerLink) || ''"
              target="_blank">
            <img :src="getBannerUri" :alt="details.companyName" style="max-width:600px; margin-top:20px;"
                 :style="{ maxWidth: details.bannerMaxWidth + 'px' }">
          </component>
        </td>
      </tr>
      <tr>
        <td style="font-family:sans-serif;">
          <p v-if="details.legalDisclaimer" style="line-height: 125%; font-size:11px; max-width:600px;">
            <span v-html="nl2br(details.legalDisclaimer)"
                  :style="{ fontSize: details.legalDisclaimerFontSize + 'px', color: details.legalDisclaimerFontColor }"></span>
          </p>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import signatureMixin from '../../mixins/signature.js';

export default {
  mixins: [signatureMixin],
  data() {
    return {
      'styleRef': 'style3',
    }
  },
}
</script>
