import {createApp} from 'vue'
import "./scss/main.scss";
import * as bootstrap from "bootstrap";
import App from './App.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import 'bootstrap-icons/font/bootstrap-icons.css';

import SigStyle1 from './components/sigs/style1'
import SigStyle2 from './components/sigs/style2'
import SigStyle3 from './components/sigs/style3'
import SigStyle4 from './components/sigs/style4'

const app = createApp(App)

app.use(VueAxios, axios, bootstrap)
app.component('sig-style1', SigStyle1);
app.component('sig-style2', SigStyle2);
app.component('sig-style3', SigStyle3);
app.component('sig-style4', SigStyle4);
app.mount('#app');

