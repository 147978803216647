<template>
  <div class="toast-container position-fixed bottom-0 end-0 p-3">
    <template v-for="toast in toasts" :key="toast.title + randomKey()">
      <Toast
          :title="toast.title"
          :body="toast.body"
          :show="toast.show"
          :type="toast.type"
          @close="closeToast(toast)"

      ></Toast>
    </template>
  </div>
  <div class="form">
    <nav class="navbar navbar-light bg-light">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <span class="navbar-brand mb-0 h1"><svg xmlns="http://www.w3.org/2000/svg" height="32" viewBox="0 0 640 512"><path fill="#e26700" d="M192 128c0-17.7 14.3-32 32-32s32 14.3 32 32v7.8c0 27.7-2.4 55.3-7.1 82.5l-84.4 25.3c-40.6 12.2-68.4 49.6-68.4 92v71.9c0 40 32.5 72.5 72.5 72.5c26 0 50-13.9 62.9-36.5l13.9-24.3c26.8-47 46.5-97.7 58.4-150.5l94.4-28.3-12.5 37.5c-3.3 9.8-1.6 20.5 4.4 28.8s15.7 13.3 26 13.3H544c17.7 0 32-14.3 32-32s-14.3-32-32-32H460.4l18-53.9c3.8-11.3 .9-23.8-7.4-32.4s-20.7-11.8-32.2-8.4L316.4 198.1c2.4-20.7 3.6-41.4 3.6-62.3V128c0-53-43-96-96-96s-96 43-96 96v32c0 17.7 14.3 32 32 32s32-14.3 32-32V128zm-9.2 177l49-14.7c-10.4 33.8-24.5 66.4-42.1 97.2l-13.9 24.3c-1.5 2.6-4.3 4.3-7.4 4.3c-4.7 0-8.5-3.8-8.5-8.5V335.6c0-14.1 9.3-26.6 22.8-30.7zM24 368c-13.3 0-24 10.7-24 24s10.7 24 24 24H64.3c-.2-2.8-.3-5.6-.3-8.5V368H24zm592 48c13.3 0 24-10.7 24-24s-10.7-24-24-24H305.9c-6.7 16.3-14.2 32.3-22.3 48H616z"/></svg> SimpleSigs</span>
          </div>
        </div>
      </div>
    </nav>
    <div v-if="!authorised" class="container">
      <div class="row">
        <div class="col-sm-6">
          <label for="name" class="form-label">Username</label>
          <input type="text" id="username" class="form-control" v-model.lazy="admin.username" required>
        </div>
        <div class="col-sm-6">
          <label for="job-title" class="form-label">Password</label>
          <input type="password" id="password" class="form-control" v-model="admin.password" required>
        </div>
      </div>
    </div>
    <div class="container-fluid pb-3 pt-1" v-if="authorised">
      <div class="row">
        <div class="col-12">
        </div>
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <a class="nav-link" :class="{active: currentPage==='people'}" href="#" @click.prevent="currentPage = 'people'">People</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" :class="{active:currentPage==='companies'}" href="#" @click.prevent="currentPage = 'companies'">Companies</a>
          </li>
        </ul>
      </div>
    </div>
    <template v-if="authorised">
      <div v-show="currentPage === 'people'" class="container-fluid">
        <people></people>
      </div>
      <div v-show="currentPage === 'companies'" class="container-fluid">
        <companies></companies>
      </div>
    </template>
  </div>
</template>

<script>
import Companies from "@/components/Companies";
import People from "@/components/People";
import client from "@/mixins/api/client"
import shared from "@/mixins/api/shared"
import Toast from "@/components/shared/Toast.vue";


export default {
  name: 'app',
  mixins: [shared, client],
  components: {Companies, People, Toast},
  data() {
    return {
      model: 'person',
      baseUrl: 'http://simplesigs.com',
      currentPage: 'people',
      admin: {
        'username': '',
        'password': ''
      },
      toasts: [],
    }
  },
  mounted() {
    this.baseUrl = process.env.VUE_APP_BASEURL;
  },
  methods: {
    hashCode(s) {
      return s.split('').reduce((a, b) => (((a << 5) - a) + b.charCodeAt(0)) | 0, 0)
    },
    randomKey() {
      return (new Date()).getTime() + Math.floor(Math.random() * 10000).toString()
    },
    closeToast(toast) {
      toast.show = false;
    },
  },
  computed: {
    authorised() {
      if (localStorage.getItem('isLoggedIn') === 'true') {
        return true;
      } else if (this.hashCode(this.admin.username) === 3023879 && this.hashCode(this.admin.password) === -722411109) {
        localStorage.setItem('isLoggedIn', 'true');
        return true;
      }
      return false;
    },

  },
}
</script>


<style lang="css">


.form {
  padding-bottom: 30px;
}

.signature-html {
  display: inline-block;
  width: calc(100% - 30px);
  height: 120px;
  margin-bottom: 10px;
  word-wrap: break-word;
  overflow-y: scroll;

}

.signature-container {
  padding: 30px;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  float: right;
}

</style>
