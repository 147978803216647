<template>
  <div class="toast" :class="{'show': localShow, 'hide' : !localShow}" role="alert" aria-live="assertive" aria-atomic="true">
    <div class="toast-header">
      <template v-if="type === 'error'">
        <i class="bi bi-exclamation-square-fill text-danger"></i>&nbsp;
      </template>
      <template v-else-if="type === 'info'">
        <i class="bi info-square-fill text-primary"></i>&nbsp;
      </template>
      <template v-else>
        <i class="bi bi-check-square-fill text-success"></i>&nbsp;
      </template>
      <strong class="me-auto">{{ title }}</strong>
      <small></small>
      <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
    </div>
    <div class="toast-body">
      {{ body }}
    </div>
  </div>
</template>

<script>
export default {
  name: "Toast",
  props: {show: Boolean, title: String, body: String, timeout: Number, type: String},
  emits: ['close'],
  mounted() {
    console.log(this.localShow);
    setTimeout(() => {
      this.localShow = false;
      this.$emit('close');
    }, this.localTimeout)
  },
  data() {
    return {
      localShow: this.show,
      localTimeout: (this.timeout) ? this.timeout : 10000,
    }
  },
}
</script>

<style>
</style>
