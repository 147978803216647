<template>
  <button @click="toggleDesign"
          class="btn btn-sm btn-secondary" style=" position:fixed; z-index:10001; right:5px; left:auto; top:5px;" type="button">
    Toggle Design
  </button>
  <div class="row">
    <div :class="{'col-md-6': showDesign, 'col-12': !showDesign}">
      <div class="row mb-3">
        <div class="col-md-5">
          <label for="existing-company-name" class="form-label">Company</label>
          <select name="existing-company-name" id="existing-company-name" class="form-select" v-model="selectedCompany" v-on:change="onCompanySelected()">
            <option value="">All</option>
            <option v-for="company in companies" :key="company" :value="company">{{ getNameFormatted(company) }}</option>
          </select>
        </div>
        <div class="col-md-7">
          <label for="existing-client-name" class="form-label">People</label>
          <select name="existing-client-name" id="existing-client-name" class="form-select" v-model="selectedClient" v-on:change="updateForm()">
            <option value="">Add New Person...</option>
            <option v-for="client in clients" :key="client" :value="client">{{ getPersonNameFormatted(client) }}</option>
          </select>
        </div>
        <!--<div class="col-sm-6">
          <label for="company-name" class="form-label">Company Name</label>
          <input type="text" id="company-name" class="form-control" v-model="details.companyName" required>
        </div>-->
        <div class="col-sm-12 pt-3 pb-3" v-if="selectedClient">
          <div class="alert alert-primary d-flex justify-content-between" role="alert"
               v-if="!isPartOfCompany && selectedCompany">
            This person is not part of a company. Would you like to merge in the company info?
            <button class="btn btn-primary btn-sm" @click="mergeCompanySettings">Merge</button>
          </div>

          <div class="alert alert-secondary d-flex justify-content-between" role="alert"
               v-if="isPartOfCompany && selectedCompany">Update person info from company record
            <button class="btn btn-primary btn-sm" @click="mergeCompanySettings">Merge</button>
          </div>

        </div>
      </div>
      <template v-if="hasCompanyName">
        <template v-if="hasLogo">
          <div class="card mb-3">
            <div class="card-header">
              Personal Details
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <h6>Name & Job</h6>
                </div>
                <div class="col-sm-6">
                  <label for="name" class="form-label">Name</label>
                  <input type="text" id="name" class="form-control" v-model.lazy="details.name" @change="updateCompanyName" required>
                </div>
                <div class="col-sm-6">
                  <label for="job-title" class="form-label">Job Title</label>
                  <input type="text" id="job-title" class="form-control" v-model.lazy="details.jobTitle">
                </div>
                <div class="col-sm-12 mt-3">
                  <h6>Email</h6>
                </div>
                <div class="col-sm-6">
                  <label for="email__label" class="form-label">Label</label>
                  <input type="text" id="email__label" class="form-control" v-model.lazy="details.email.label">
                </div>
                <div class="col-sm-6">
                  <label for="email__address" class="form-label">Email address</label>
                  <input type="email" id="email__address" class="form-control" v-model.lazy="details.email.address">
                </div>
                <div class="col-sm-12 mt-3">
                  <h6>Phone 1</h6>
                </div>
                <div class="col-sm-6">
                  <label for="phone1__label" class="form-label">Label</label>
                  <input type="text" id="phone1__label" class="form-control" v-model.lazy="details.phone1.label">
                </div>
                <div class="col-sm-6">
                  <label for="phone1__number" class="form-label">Number</label>
                  <input type="text" id="phone1__number" class="form-control" v-model.lazy="details.phone1.number">
                </div>
                <div class="col-sm-12 mt-3">
                  <h6>Phone 2</h6>
                </div>
                <div class="col-sm-6">
                  <label for="phone2__label" class="form-label">Label</label>
                  <input type="text" id="phone2__label" class="form-control" v-model.lazy="details.phone2.label">
                </div>
                <div class="col-sm-6">
                  <label for="phone2__number" class="form-label">Number</label>
                  <input type="text" id="phone2__number" class="form-control" v-model.lazy="details.phone2.number">
                </div>
              </div>
            </div>
          </div>

          <div class="card mb-3">
            <div class="card-header">Company Details</div>
            <div class="card-body">
              <div class="row">
                <div class="col-12" v-if="isPartOfCompany">
                  <div class="alert alert-primary" role="alert">
                    The following fields were copied from the company, any changes to them will be over-written if you merge in company info.
                  </div>
                </div>
                <div class="col-sm-12">
                  <h6>Logo</h6>
                  <div class="input-group">
                    <input type="file" id="logo" ref="logo" class="form-control" v-on:change="handleLogoUpload()" required/>
                    <button v-on:click.prevent="submitLogo()" class="btn btn-secondary" type="button">Upload</button>
                  </div>
                </div>
                <div class="col-sm-12 mt-3">
                  <h6>Website</h6>
                </div>
                <div class="col-sm-6">
                  <label for="website__label" class="form-label">Label</label>
                  <input type="text" id="website__label" class="form-control" v-model.lazy="details.website.label">
                </div>
                <div class="col-sm-6">
                  <label for="website__url" class="form-label">URL</label>
                  <input type="text" id="website__url" class="form-control" v-model.lazy="details.website.url">
                </div>
                <div class="col-12">
                  <div class="form-check">
                    <label for="link_logo" class="form-check-label">Link logo?</label>
                    <input type="checkbox" id="link_logo" class="form-check-input" v-model="details.website.linkLogo">
                  </div>
                </div>
                <div class="col-sm-12 mt-3">
                  <h6>Social Media</h6>
                </div>
                <div class="col-sm-6">
                  <label for="facebook" class="form-label">Facebook</label>
                  <input type="text" id="facebook" class="form-control" v-model.lazy="details.facebook">
                </div>
                <div class="col-sm-6">
                  <label for="linkedin" class="form-label">LinkedIn</label>
                  <input type="text" id="linkedin" class="form-control" v-model.lazy="details.linkedin">
                </div>
                <div class="col-sm-6 mt-3">
                  <label for="instagram" class="form-label">Instagram</label>
                  <input type="text" id="instagram" class="form-control" v-model.lazy="details.instagram">
                </div>
                <div class="col-sm-6 mt-3">
                  <label for="twitter" class="form-label">Twitter</label>
                  <input type="text" id="twitter" class="form-control" v-model.lazy="details.twitter">
                </div>
                <div class="col-sm-6 mt-3">
                  <label for="youtube" class="form-label">YouTube</label>
                  <input type="text" id="youtube" class="form-control" v-model.lazy="details.youtube">
                </div>
                <div class="col-12 mt-3">
                  <label for="social-icon-color" class="form-label">Social Icon Colour</label><br/>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="socialIconDefault" id="socialIconDefault" value="default"
                           v-model="details.socialIconColour"/>
                    <label class="form-check-label" for="socialIconDefault">Default</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="socialIconPlatform" id="socialIconPlatform" value="platform"
                           v-model="details.socialIconColour"/>
                    <label class="form-check-label" for="socialIconPlatform">Platform correct</label>
                  </div>
                </div>
                <div class="col-sm-12 mt-3">
                  <h6>Extra Link</h6>
                </div>
                <div class="col-sm-6">

                  <label for="extra-link-label" class="form-label">Label</label>
                  <input type="text" id="extra-link-label" class="form-control" v-model.lazy="details.extraLink.label">
                </div>
                <div class="col-sm-6">
                  <label for="extra-link-url" class="form-label">URL</label>
                  <input type="text" id="extra-link-url" class="form-control" v-model.lazy="details.extraLink.link">
                </div>
                <div class="col-sm-12 mt-3">
                  <h6>Extra Text</h6>
                </div>
                <div class="col-sm-12">
                  <label for="extra-text" class="form-label">Extra Text/HTML</label>
                  <textarea type="text" id="extra-text" class="form-control" v-model.lazy="details.extraText"></textarea>
                </div>
                <div class="col-sm-12">
                  <div class="row">
                    <div class="col-auto">
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="extraTextAbove" id="extraTextAbove" value="above"
                               v-model="details.extraTextPosition"/>
                        <label class="form-check-label" for="extraTextAbove">Above</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="extraTextBelow" id="extraTextBelow" value="below"
                               v-model="details.extraTextPosition"/>
                        <label class="form-check-label" for="extraTextBelow">Below</label>
                      </div>
                    </div>
                    <div class="col-auto">social icons</div>
                  </div>
                </div>
                <div class="col-sm-12 mt-3">
                  <h6>Call To Action</h6>
                </div>
                <div class="col-sm-5">
                  <div class="form-check">
                    <label for="show-banner" class="form-check-label">Show Call To Action</label>
                    <input type="checkbox" id="show-banner" class="form-check-input" v-model="details.showBanner">
                  </div>
                </div>
                <div class="col-sm-3">
                  <label for="banner-max-width" class="form-check-label">Max width</label>
                  <div class="input-group">
                    <input type="number" id="banner-max-width" class="form-control"
                           v-model="details.bannerMaxWidth">
                    <span class="input-group-text">px</span>
                  </div>
                </div>
                <div class="col-sm-12">
                  <label for="banner" class="form-label">Call To Action Image</label>
                  <div class="input-group">
                    <input type="file" id="banner" ref="banner" class="form-control" v-on:change="handleBannerUpload()" required/>
                    <button v-on:click.prevent="submitBanner()" class="btn btn-secondary">Upload</button>
                  </div>
                </div>
                <div class="col-12 mt-3">
                  <label for="banner-link" class="form-label">URL</label>
                  <input type="text" id="banner-link" class="form-control" v-model.lazy="details.bannerLink">
                </div>
                <div class="col-sm-12 mt-3">
                  <h6>Branding</h6>
                </div>
                <div class="col-sm-4">
                  <label for="brand-colour" class="form-label">Brand Colour</label>
                  <input type="color" id="brand-colour" class="form-control" v-model="details.brandColour">
                </div>
                <div class="col-sm-4">
                  <label for="brand-colour-text" class="form-label">Text Brand Colour</label>
                  <input type="color" id="brand-colour-text" class="form-control" v-model="details.brandColourText">
                </div>
                <div class="col-sm-4">
                  <label for="brand-colour-line-width" class="form-label">Line Width</label>
                  <div class="input-group">
                    <input type="number" id="brand-colour-line-width" class="form-control"
                           v-model="details.brandColourLineWidth">
                    <span class="input-group-text">px</span>
                  </div>
                </div>

                <div class="col-sm-12 mt-3">
                  <h6>Legal Disclaimer</h6>
                </div>
                <div class="col-sm-12">
                  <label for="legal-disclaimer" class="form-label">Legal Disclaimer</label>
                  <textarea type="text" id="legal-disclaimer" class="form-control" v-model.lazy="details.legalDisclaimer"></textarea>
                </div>
                <div class="col-sm-3 mt-3">
                  <label for="legal-disclaimer-font-size" class="form-label">Font Size</label>
                  <div class="input-group">
                    <input type="number" id="legal-disclaimer-font-size" class="form-control"
                           v-model.lazy="details.legalDisclaimerFontSize">
                    <span class="input-group-text">px</span>
                  </div>
                </div>
                <div class="col-sm-4 mt-3">
                  <label for="legal-disclaimer-font-color" class="form-label">Text Color</label>
                  <input type="color" id="legal-disclaimer-font-color" class="form-control" v-model="details.legalDisclaimerFontColor">
                </div>
              </div>
            </div>
          </div>
          <button type="button" class="btn btn-primary text-light sticky-bottom align-self-end mt-1"
                  v-on:click.prevent="saveClient()">Save
          </button>
        </template>
      </template>
    </div>


    <div class="col-md-6" v-show="showDesign">
      <div class="bg-light pt-3 pb-3 ps-3 pe-3 show sticky-top">
        <signature-preview
            :details="details"
            :baseUrl="$parent.baseUrl"
        ></signature-preview>
      </div>
    </div>
  </div>
</template>

<script>
import shared from "@/mixins/api/shared";
import client from "@/mixins/api/client";
import company from "@/mixins/api/company";
import _clonedeep from "lodash/cloneDeep";
import SignaturePreview from "@/components/SignaturePreview";

export default {
  name: 'people',
  components: {SignaturePreview},
  mixins: [shared, client, company],
  data() {
    return {
      model: 'person',
      selectedClient: '',
      selectedCompany: '',
      showDesign: true,
      logo: '',
      banner: '',
      defaults: {},
      clients: [],
      companies: [],
      details:
          {
            name: '',
            jobTitle: '',
            companyName: '',
            email: {label: 'Email', address: ''},
            phone1: {label: 'Office', number: ''},
            phone2: {label: 'Mobile', number: ''},
            website: {label: 'Website', url: '', linkLogo: true},
            facebook: '',
            linkedin: '',
            instagram: '',
            twitter: '',
            youtube: '',
            socialIconColour: 'default',
            extraLink: {
              label: '',
              link: ''
            },
            extraText: '',
            extraTextPosition: 'below',
            brandColour: '',
            brandColourLineWidth: '3',
            brandColourText: '',
            legalDisclaimer: '',
            legalDisclaimerFontSize: '11',
            legalDisclaimerFontColor: '#333333',
            logoUri: '',
            showBanner: false,
            bannerMaxWidth: '600',
            bannerUri: '',
            bannerLink: '',
          },
    }
  },
  mounted() {
    //this.baseUrl = process.env.VUE_APP_BASEURL;
    this.saveDefault();
    this.getClients();
    this.getCompanies();
  },
  methods: {
    async mergeCompanySettings() {
      const personalDetails = {
        name: this.details.name,
        jobTitle: this.details.jobTitle,
        email: this.details.email,
        phone1: this.details.phone1,
        phone2: this.details.phone2
      }
      await this.getCompanySettings().then(() => {
        this.details = {
          ...this.details,
          ...personalDetails
        }
        this.updateCompanyName();
      })

    },
    onCompanySelected() {
      this.getClients();
      this.getCompanySettings();
    },
    updateCompanyName() {
      if (this.selectedCompany) {
        this.details.companyName = this.selectedCompany + ' - ' + this.details.name;
      }
    },
    resetDetails() {
      this.details = {...this.defaults};
      this.details = _clonedeep(this.defaults);
    },
    handleLogoUpload() {
      this.logo = this.$refs.logo.files[0];
    },
    handleBannerUpload() {
      this.banner = this.$refs.banner.files[0];
    },
    getNameFormatted(name) {
      return name.replace(/[-_]/g, ' ');
    },
    getPersonNameFormatted(name) {
      if (name.toLowerCase() !== this.selectedCompany.toLowerCase()) {
        name = name.replace(this.selectedCompany, '')
      }
      return this.getNameFormatted(name);
    },
    updateForm() {
      if (this.selectedClient === '') {
        this.details = {...this.defaults};
        this.getCompanySettings();
      } else {
        this.getClientSettings();
      }
    },
    saveDefault() {
      this.defaults = {...this.details}
    },
    setDefaults() {
      if (this.details.legalDisclaimerFontSize === '') {
        this.details.legalDisclaimerFontSize = '11';
      }
      if (this.details.brandColourLineWidth === '') {
        this.details.brandColourLineWidth = '3';
      }
      if (this.details.bannerMaxWidth === '') {
        this.details.bannerMaxWidth = '600';
      }
    },
    toggleDesign() {
      this.showDesign = !this.showDesign;
    }
  },
  computed: {
    hasCompanyName() {
      return (this.details.companyName !== '');
    },
    hasLogo() {
      return (this.details.logoUri !== '');
    },
    hasClients() {
      return (Object.keys(this.clients).length > 0);
    },
    hasCompanies() {
      return (Object.keys(this.companies).length > 0);
    },
    isPartOfCompany() {
      return this.details.logoUri.includes('/companies/')
    }
  },
}
</script>

<style scoped>

</style>
