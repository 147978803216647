import axios from "axios";

export default {
    methods: {
        submitLogo() {
            let self = this;
            let formData = new FormData();
            formData.append('logo', this.logo);
            formData.append('company_name', this.details.companyName)
            formData.append('model', this.model)
            axios.post('/api/upload_logo.php',
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            ).then(function (response) {
                if (response.data.success) {
                    self.details.logoUri = response.data.logo_file + '?a=' + (Math.floor(Math.random() * 10000) + 1);
                    self.$parent.toasts.push({
                        title: 'Logo saved',
                        body: "All good, logo saved fine",
                        type: 'success',
                        show: true
                    });
                }
            })
                .catch(function (e) {
                    console.log('Failed: ' + e);
                    self.$parent.toasts.push({
                        title: 'Logo error',
                        body: "There was an issue saving this logo",
                        type: "error",
                        show: true
                    });
                });
        },
        submitBanner() {
            var self = this;
            let formData = new FormData();
            formData.append('banner', this.banner);
            formData.append('company_name', this.details.companyName)
            formData.append('model', this.model)
            axios.post('/api/upload_banner.php',
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            ).then(function (response) {
                if (response.data.success) {
                    self.details.bannerUri = response.data.banner_file + '?a=' + (Math.floor(Math.random() * 10000) + 1);
                    self.details.showBanner = true;
                    self.$parent.toasts.push({
                        title: 'Banner saved',
                        body: "All good, banner saved fine",
                        type: 'success',
                        show: true
                    });
                }
            })
                .catch(function (e) {
                    console.log('Failed: ' + e);
                    self.$parent.toasts.push({
                        title: 'Banner error',
                        body: "There was an issue saving this banner",
                        type: "error",
                        show: true
                    });
                });
        },
    }
}
